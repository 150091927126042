<template>
  <div v-loading.fullscreen.lock="isLoading">
    <el-card shadow="always">
      <div slot="header">
        <span class="title">查詢條件</span>
      </div>

      <el-form
        label-width="80px"
        label-position="left"
        :model="queryData"
        id="queryForm"
        :rules="rules"
      >
        <el-row :gutter="40">
          <el-col :sm="8">
            <el-form-item label="組織名稱">
              <el-input
                v-model="queryData.companyName"
                placeholder="請輸入組織名稱"
                style="width: 90%"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :sm="8">
            <el-form-item label="公司統編">
              <el-input
                v-model="queryData.companyUbn"
                placeholder="請輸入公司統編"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :sm="8">
            <el-form-item label="狀態" label-width="50px">
              <el-select
                v-model="queryData.status"
                clearable
                placeholder="請選擇狀態"
              >
                <el-option label="啟用" value="ENABLED"></el-option>
                <el-option label="停用" value="DISABLED"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <!-- <el-col :sm="8">
            <el-form-item label="營利屬性">
              <el-select
                v-model="queryData.companyIsNgo"
                clearable
                placeholder="請選擇營利屬性"
                style="width: 90%"
              >
                <el-option label="營利" :value="false"></el-option>
                <el-option label="非營利" :value="true"></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->

          <!-- <el-col :sm="10">
            <el-form-item label="電子信箱">
              <el-input
                v-model="queryData.ownerEmail"
                placeholder="請輸入電子信箱"
                style="width: 75%"
              ></el-input>
            </el-form-item>
          </el-col> -->

          <el-col :sm="8">
            <el-form-item label="商店名稱">
              <el-input
                v-model="queryData.storeName"
                placeholder="請輸入商店名稱"
                style="width: 90%"
              ></el-input>
            </el-form-item>
          </el-col>

          <!-- <el-col :sm="8">
            <el-form-item label="商店代號">
              <el-input
                v-model="queryData.storeMerchantId"
                placeholder="請輸入商店代號"
              ></el-input>
            </el-form-item>
          </el-col> -->
        </el-row>
      </el-form>

      <el-row type="flex" justify="end">
        <el-button type="primary" @click="query">搜尋</el-button>
        <el-button @click="clear">全部清除</el-button>
      </el-row>
    </el-card>

    <br />

    <el-card>
      <div slot="header" style="line-height: 40px">
        <span class="title">組織列表</span>

        <el-button style="float: right; margin: 5px" type="primary" @click="add"
          >新增組織</el-button
        >
      </div>
      <el-table :data="tableData" id="customerResult" empty-text="暫無數據">
        <!-- <el-table-column type="expand">
          <template slot-scope="props">
            <div
              v-for="(item, index) in props.row.storeList"
              :key="item.id"
              style="border-bottom: 1px #cdd0d6 solid"
            >
              <div :key="index">
                <el-row
                  :gutter="20"
                  style="height: 40px; padding: 10px 0px; background: #ebedef"
                >
                  <el-col :span="1" style="width: 50px">
                    <span>&nbsp;&nbsp;&nbsp;</span>
                  </el-col>
                  <el-col
                    :span="4"
                    type="flex"
                    align="middle"
                    style="width: 220px"
                  >
                    <el-link
                      type="primary"
                      :underline="false"
                      v-on:click="openStoreEdit(item.id)"
                    >
                      {{ item.name }}
                    </el-link>
                  </el-col>
                  <el-col
                    :span="3"
                    type="flex"
                    align="middle"
                    style="padding-left: 20px; width: 180px"
                  >
                    <span>{{ item.merchantId }}</span>
                  </el-col>
                </el-row>
              </div>
            </div>
          </template>
        </el-table-column> -->

        <el-table-column
          show-overflow-tooltip
          prop="name"
          label="組織名稱"
          width="220"
          header-align="center"
          align="center"
        >
        </el-table-column>

        <el-table-column
          prop="ubn"
          label="公司統編"
          width="180"
          header-align="center"
          align="center"
        >
        </el-table-column>

        <!-- <el-table-column
          prop="ownerEmail"
          label="電子信箱"
          min-width="180"
          header-align="center"
          align="center"
        >
        </el-table-column> -->

        <!-- <el-table-column
          prop="isNgo"
          label="營利屬性"
          min-width="180"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            <span v-show="scope.row.isNgo">非營利</span>
            <span v-show="!scope.row.isNgo">營利</span>
          </template>
        </el-table-column> -->

        <el-table-column
          prop="storeNumber"
          label="商店數"
          min-width="180"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            <span> {{ getStoreNum(scope.row) }} </span>
          </template>
        </el-table-column>

        <el-table-column
          prop="status"
          label="狀態"
          min-width="120"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            <span v-show="scope.row.status == 'ENABLED'" class="span-success"
              >啟用</span
            >
            <span v-show="scope.row.status == 'DISABLED'" class="span-danger"
              >停用</span
            >
          </template>
        </el-table-column>

        <el-table-column
          label="操作"
          min-width="120"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              v-on:click="openDetail(scope.row)"
              type="primary"
              >編輯</el-button
            >

            <el-button
              size="mini"
              v-on:click="store(scope.row.id)"
              type="primary"
              >商店</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <div class="block">
        <span class="demonstration"></span>
        <el-pagination
          class="pagination"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pagesize"
          layout="prev, pager, next"
          :total="totalCount"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import API from "../../api";
import Utils from "../../utils";

export default {
  name: "CustomerManage",

  data() {
    return {
      isLoading: false, // 是否加載數據
      queryData: {
        companyName: null,
        companyUbn: null,
        status: "ENABLED",
        companyIsNgo: false,
        ownerEmail: null,
        storeName: null,
        storeMerchantId: null,
      },
      queryDataTemp: null, //查詢條件temp字串
      tableData: [],
      currentPage: 1,
      pagesize: 10,
      totalCount: null,
      dialogUse: {
        storeAddVisible: false,
        companyId: null,
        clickTimestoreAdd: null,
        storeEditVisible: false,
        storeId: null,
        clickTimestoreEdit: null,
      },
      // 切換router 紀錄：查詢條件/目前分頁頁數
      reouterBefore: null,
      rules: {
        ownerEmail: [
          {
            required: false,
            message: "電子信箱必填",
            trigger: ["blur", "change"],
          },
          {
            type: "email",
            message: "請輸入正確的電子信箱",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },

  created() {
    // 1. 檢測 若不是來自指定編輯頁path，預設清除 紀錄在sessionStorage的內容
    if (!Utils.Auth.checkIsFromSpecifiedPath(["/customerManage/edit"])) {
      // 清除 紀錄在sessionStorage的內容
      sessionStorage.setItem("CustomerManage", null);
    }

    // 2. 查詢
    this.query();
  },

  methods: {
    clear() {
      this.queryData.companyName = null;
      this.queryData.companyUbn = null;
      this.queryData.status = "ENABLED";
      this.queryData.companyIsNgo = false;
      this.queryData.ownerEmail = null;
      this.queryData.storeName = null;
      this.queryData.storeMerchantId = null;
    },

    query() {
      // 1. 預設 初始化查詢條件
      this.currentPage = 1;
      this.queryDataTemp = JSON.stringify(this.queryData);

      // 2. 檢查 sessionStorage 切換編輯畫面前的查詢條件及分頁
      this.reouterBefore = sessionStorage.getItem("CustomerManage");
      if (!Utils.Check.isNull(this.reouterBefore)) {
        var param = JSON.parse(this.reouterBefore);
        if (!Utils.Check.isNull(param?.currentPage))
          this.currentPage = param.currentPage;
        if (!Utils.Check.isNull(param?.queryDataTemp)) {
          this.queryDataTemp = param.queryDataTemp;
          this.queryData = JSON.parse(param.queryDataTemp);
        }
      }

      // 3. 查詢
      this.customerManageQuery();

      // 4. 清除 紀錄在sessionStorage的內容
      sessionStorage.setItem("CustomerManage", null);
    },

    //分頁 初始頁currentPage、初始每頁數據數pagesize和數據testpage--->控制每頁幾條
    handleSizeChange: function (size) {
      this.pagesize = size;
      this.customerManageQuery();
    },
    // 控制頁面的切換
    handleCurrentChange: function (currentPage) {
      this.currentPage = currentPage;
      this.customerManageQuery();
    },

    customerManageQuery() {
      this.isLoading = true;
      let params = {
        currentPage: `${this.currentPage}`,
        pagesize: `${this.pagesize}`,
        queryData: JSON.parse(this.queryDataTemp),
      };
      API.customer
        .query(params)
        .then((res) => {
          this.tableData = res.data.result;
          this.totalCount = res.data.totalCount;
          this.isLoading = false;
        })
        .catch((e) => {
          console.log(`query error`);
          Message({
            message: "查詢失敗!",
            type: "error",
            duration: 3 * 1000,
          });
          this.isLoading = false;
        });
    },

    // 新增組織
    add() {
      this.$router.push({ path: `/customerManage/edit` });
    },

    store(id) {
      this.$router.push({
        path: `/customerManage/store`,
        query: { id: `${id}` },
      });
    },

    openDetail(row) {
      // 1. 紀錄 打開編輯畫面，當下 查詢條件及目前分頁頁數，紀錄在sessionStorage裡面。
      this.reouterBefore = {
        queryDataTemp: this.queryDataTemp,
        currentPage: this.currentPage,
      };
      sessionStorage.setItem(
        "CustomerManage",
        JSON.stringify(this.reouterBefore)
      );

      // 2. 打開編輯組織 編輯頁
      this.$router.push({
        path: `/customerManage/edit`,
        query: { id: `${row.id}` },
      });
    },

    // 取得 組織的商店數
    getStoreNum(data) {
      if (Utils.Check.isNull(data.storeList)) {
        return "0";
      } else {
        return `${data.storeList.length}`;
      }
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 16px;
  font-weight: bold;
}

.pagination {
  margin-top: 10px;
  margin-left: 35%;
}

::v-deep .span-secondary {
  color: var(--secondary) !important;
}
::v-deep .span-success {
  color: var(--success) !important;
}
::v-deep .span-warning {
  color: var(--warning) !important;
}
::v-deep .span-danger {
  color: var(--danger) !important;
}

::v-deep .el-table__expanded-cell {
  background-color: #ebedef;
  padding: 0px 0px;
}
</style>
